import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

import { CONTACT_ME_PAGE } from "src/routesConstants";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Pricing",
	subTitle: null
};

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	const title = "Pricing";
	const description = "How do we price?";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section className="container outerContainer m-t-lg m-b-xl">
				<p>
					The short answer is get in touch and we&apos;ll work out a
					quote. We&apos;ve scoped many different data requirements
					for SEO teams and they vary a lot.
				</p>
				<p>
					As a rough guideline for sites using our data service (i.e.
					not a data product like rank tracking, orm etc.) our current
					minimum is $750 (~€690, ~£590) a month.
				</p>
				<div className="columns is-desktop">
					<div className="column">
						<div className="pricingBox">
							<h3>Just a warehouse</h3>
							<p>
								Designed for teams who are already used to data
								warehouses and need the hard parts sorted for
								them. We&apos;ll run a set-up project and build
								pipelines to load all of your data into a
								warehouse.
							</p>
							<ul>
								<li>Set-up project</li>
								<li>Loading data sources</li>
								<li>Support with data loading issues</li>
								<li>Basic transformation pipelines</li>
								<li>Access to the Piped Out Query platform</li>
							</ul>
							<a
								className={`button is-link is-primary`}
								href={CONTACT_ME_PAGE}
							>
								Book a demo
							</a>
						</div>
					</div>
					<div className="column">
						<div className="pricingBox inverted">
							<h3>Standard data service</h3>
							<p>
								Designed for teams who want the most out of
								their data, but aren&apos;t comfortable with
								data warehouses. Includes all of the basics
								plus.
							</p>
							<ul>
								<li>Complex transformation pipelines</li>
								<li>Default reporting templates</li>
								<li>Basic customisation</li>
								<li>Reporting & data support</li>
								<li>Team training</li>
							</ul>
							<a
								className={`button is-link is-primary`}
								href={CONTACT_ME_PAGE}
							>
								Book a demo
							</a>
						</div>
					</div>
					<div className="column">
						<div className="pricingBox">
							<h3>Complex & Custom</h3>
							<p>
								Designed for teams who need customisation &
								support above the other levels. Includes all of
								standard plus:
							</p>
							<ul>
								<li>Custom reports</li>
								<li>Customer success partner</li>
								<li>Custom data sources</li>
							</ul>
							<a
								className={`button is-link is-primary`}
								href={CONTACT_ME_PAGE}
							>
								Book a demo
							</a>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
// export const pageQuery = graphql`
// 	query LoadingData {
// 		# This gets the main image
// 		primaryImage: file(
// 			relativePath: {
// 				eq: "images/pages/homepage/homepage_stripprimary.jpg"
// 			}
// 		) {
// 			childImageSharp {
// 				fluid(maxWidth: 935, maxHeight: 575) {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 		# Get us the main alternating images
// 		alternatingImg0: file(
// 			relativePath: {
// 				eq: "images/pages/how_does_it_work/people_small.png"
// 			}
// 		) {
// 			childImageSharp {
// 				fluid(maxWidth: 501, maxHeight: 393) {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 		alternatingImg1: file(
// 			relativePath: { eq: "images/pages/homepage/bigquery_tables2.png" }
// 		) {
// 			childImageSharp {
// 				fluid(maxWidth: 546, maxHeight: 326) {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 		alternatingImg2: file(
// 			relativePath: { eq: "images/pages/how_does_it_work/bigquery.jpg" }
// 		) {
// 			childImageSharp {
// 				fluid(maxWidth: 672, maxHeight: 392) {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 	}
// `;
