import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import { REPORT_DATA, CONTACT_ME_PAGE, REPORTS } from "src/routesConstants";
import styles from "./StripPrimary.module.scss";

const propTypes = {
	image: PropTypes.shape(),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	subTitle: PropTypes.element.isRequired,
	fullSized: PropTypes.bool,
	isWhite: PropTypes.bool,
	demoButton: PropTypes.bool,
	reportingButton: PropTypes.bool,
	includeIntroVideo: PropTypes.bool
};

const defaultProps = {
	fullSized: true,
	image: null,
	isWhite: false,
	demoButton: false,
	reportingButton: false,
	includeIntroVideo: false
};

// ${styles.whatIDoBlock
function StripPrimary({
	title,
	subTitle,
	image,
	fullSized,
	isWhite,
	demoButton,
	reportingButton,
	includeIntroVideo
}) {
	let renderedImg = null;
	let demoButtonHTML = null;
	let reportingButtonHTML;
	let backgroundBlock = null;
	let topPad = "p-t-lg";
	let botPad = "m-b-lg";
	let leftPad = "";
	let primaryBarColour = isWhite ? "" : "primaryBar";
	let includeIntroVideoHTML = null;

	if (demoButton) {
		demoButtonHTML = (
			<a
				role="button"
				className="button buttonSecondary m-r-md"
				href={CONTACT_ME_PAGE}
			>
				Book a demo
			</a>
		);
	}
	if (includeIntroVideo) {
		includeIntroVideoHTML = (
			<div
				className={`column is-half-desktop is-one-third-tablet is-full-mobile`}
			>
				<div className={`${styles.videoHolder}`}>
					<iframe
						src="https://player.vimeo.com/video/833804561?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						title="piped out intro 3"
					></iframe>
				</div>
				<script src="https://player.vimeo.com/api/player.js%22%3E" />
			</div>
		);
	}

	if (reportingButton) {
		reportingButtonHTML = (
			<a
				role="button"
				className={`button is-outlined buttonSecondary ${styles.buttonMargin}`}
				href={REPORTS}
			>
				View example reports
			</a>
		);
	}
	if (fullSized) {
		renderedImg = (
			<div
				className={`${styles.primaryImageContainer} column is-half-desktop  is-one-third-tablet is-half-mobile m-b-md `}
			>
				<Img
					fluid={image}
					alt=""
					fadeIn={false}
					className={`${styles.imageSize} forceRightAlignColumn ${styles.primaryImage}`}
				/>
			</div>
		);

		demoButtonHTML = (
			<a
				role="button"
				className="button buttonSecondary m-r-md"
				href={CONTACT_ME_PAGE}
			>
				Book an intro call
			</a>
		);

		backgroundBlock = <div className={`${styles.backgroundRec}`} />;
		topPad = "p-t-xl";
		botPad = "m-b-xl";
		leftPad = "m-b-xl";
	}

	return (
		<section
			className={`hero ${primaryBarColour} ${styles.backgroundHelper} ${topPad} innerPipeBottom`}
		>
			<div className="outerPipeBottom">
				<div
					className={`container maxContainer outerContainer ${botPad}`}
				>
					<div className="columns is-vcentered">
						<div
							className={`column is-half-desktop is-two-thirds-tablet is-full-mobile`}
						>
							<h1 className="primaryTitle">{title}</h1>
							{subTitle}
							{demoButtonHTML}
							{reportingButtonHTML}
						</div>
						{renderedImg}
						{includeIntroVideoHTML}
					</div>
				</div>
			</div>
		</section>
	);
}

StripPrimary.propTypes = propTypes;
StripPrimary.defaultProps = defaultProps;

export default StripPrimary;
