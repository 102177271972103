import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "./StripsAlternatingContent.module.scss";

const propTypes = {
	stripList: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.shape(),
			imageLarge: PropTypes.bool,
			video: PropTypes.shape(),
			content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
				.isRequired,
			icon: PropTypes.element,
			subTitle: PropTypes.oneOfType([
				PropTypes.element.isRequired,
				PropTypes.string.isRequired
			])
		})
	).isRequired,
	alternate: PropTypes.bool,
	topPad: PropTypes.bool,
	largeTitle: PropTypes.bool,
	noBotMargin: PropTypes.bool,
	classes: PropTypes.string
};

function generateStrip(
	{
		subTitle,
		content,
		secondContent,
		image,
		imageLarge,
		video,
		alignment,
		icon,
		largeTitleClass
	},
	i
) {
	const alignedClass = alignment === "left" ? "" : styles.alternate;

	let outputContent;
	const mediaAlign = alignment === "left" ? "forceRightAlignColumn" : "";
	if (secondContent) {
		outputContent = secondContent;
	} else if (image) {
		let imageRatio;

		if (image.aspectRatio > 1) {
			imageRatio = "imageSizeHorizontal";
		} else {
			// eslint-disable-next-line no-lonely-if
			if (imageLarge) {
				imageRatio = "imageSizeVerticalLarge";
			} else {
				imageRatio = "imageSizeVertical";
			}
		}

		outputContent = (
			<Img
				fluid={image}
				alt=""
				fadeIn={false}
				className={`${styles[imageRatio]} ${mediaAlign}`}
				imgStyle={{ objectFit: "contain" }}
			/>
		);
	} else if (video) {
		outputContent = (
			<div className={`${mediaAlign} ${styles.imageSizeHorizontal}`}>
				<video
					autoPlay
					muted
					loop
					playsInline
					className={`${styles.videoSize}`}
				>
					<source src={video} type="video/mp4" />
				</video>
			</div>
		);
	}

	const alignedElement = (
		<React.Fragment>
			<div
				className={`column is-half-desktop is-half-tablet is-full-mobile `}
			>
				<div className="">
					<span className="floatLeft">{icon}</span>
					<h3 className={`${largeTitleClass}`}>{subTitle}</h3>
				</div>
				{content}
			</div>
			<div
				className={`column is-half-desktop is-half-tablet is-full-mobile m-b-md `}
			>
				{outputContent}
			</div>
		</React.Fragment>
	);

	return (
		<div
			className={`columns is-vcentered ${alignedClass} p-b-xl`}
			key={`${subTitle}${i}`}
		>
			{alignedElement}
		</div>
	);
}

// ${styles.whatIDoBlock
function StripsAlternatingContent({
	stripList,
	classes,
	topPad,
	noBotMargin,
	largeTitle,
	alternate
}) {
	const largeTitleClass = largeTitle ? "is-size-3" : "is-size-5";
	const renderedStripList = stripList.map((strip, i) => {
		let alignment = "left";
		if (alternate) {
			alignment = i % 2 === 0 ? "left" : "right";
		}
		return generateStrip({ ...strip, alignment, largeTitleClass }, i);
	});
	const paddingClass = topPad ? "m-t-xl" : "";
	const botMarginClass = noBotMargin ? "" : "m-b-xl";

	return (
		<div
			className={`container maxContainer outerContainer ${botMarginClass} ${classes} ${paddingClass}`}
		>
			{renderedStripList}
		</div>
	);
}

StripsAlternatingContent.propTypes = propTypes;
StripsAlternatingContent.defaultProps = {
	classes: "",
	noBotMargin: false,
	largeTitle: false,
	alternate: true
};

export default StripsAlternatingContent;
