import React from "react";
import PropTypes from "prop-types";

import styles from "./StripTitle.module.scss";

const propTypes = {
	title: PropTypes.string.isRequired
};

// ${styles.whatIDoBlock
function StripTitle({ title }) {
	return (
		<div className={`container m-t-xl m-b-xl`}>
			<div className={`${styles.stripTitle}`}>
				<span className="primaryBoxSize2"></span>
				<h2 className="is-size-2">{title}</h2>
			</div>
		</div>
	);
}

StripTitle.propTypes = propTypes;

export default StripTitle;
